<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ $t('routes.activity') }}</h1>
        <MonthPicker v-model="selectedMonth" />
      </div>
      <p class="fw-bold">{{ $t('activity.activityFor', { month: formattedMonth }) }}</p>
    </template>
    <div class="table-container-hight pb-4 overflow-auto">
      <Table class="h-100 rounded border">
        <template #header>
          <th>
            <el-input v-model="supplierSearch" :placeholder="$t('activity.filterPlaceholder')" />
          </th>
          <th>
            <div class="action-indicator main-action">
              {{ $t(`eventMap.activitiesHeaders.order.main`) }}
            </div>
            <div class="action-indicator counter-action">
              {{ $t(`eventMap.activitiesHeaders.order.counter`) }}
            </div>
          </th>
          <th>
            <div class="action-indicator main-action">
              {{ $t(`eventMap.activitiesHeaders.delivery.main`) }}
            </div>
            <div class="action-indicator counter-action">
              {{ $t(`eventMap.activitiesHeaders.delivery.counter`) }}
            </div>
          </th>
          <th>
            <div class="action-indicator main-action">
              {{ $t(`eventMap.activitiesHeaders.billing.main`) }}
            </div>
            <div class="action-indicator counter-action">
              {{ $t(`eventMap.activitiesHeaders.billing.counter`) }}
            </div>
          </th>
        </template>
        <template #body>
          <tr
            v-for="({ supplier, orders, deliveries, billings }, index) in filteredOverviewItems"
            :key="index"
            @click="routeToSupplier(supplier.id)"
          >
            <td>{{ supplier?.name }}</td>
            <td>
              <div class="card" :class="{ main: orders.hasOrderedProducts, counter: orders.hasReturnedProducts }">
                <div class="card-body">
                  <NoticeIcon v-if="orders.hasDiffs" class="float-right warning" width="16px" height="16px" />
                  <div>{{ $tc('activity.orders', orders.count, { count: orders.count }) }}</div>
                  <div class="text-muted">{{ formatMoneyShekels(orders.amount) }}</div>
                </div>
              </div>
            </td>
            <td>
              <div
                class="card"
                :class="{ main: deliveries.hasReceivedProducts, counter: deliveries.hasReturnedProducts }"
              >
                <div class="card-body">
                  <NoticeIcon
                    v-if="deliveries.hasDiffs || deliveries.hasImbalances"
                    class="float-right"
                    :class="deliveries.hasImbalances ? 'danger' : 'warning'"
                    width="16px"
                    height="16px"
                  />
                  <div>{{ $tc('activity.deliveries', deliveries.count, { count: deliveries.count }) }}</div>
                </div>
              </div>
            </td>
            <td>
              <div class="row g-0">
                <div
                  v-if="billings.billsCount || !billings.creditsCount"
                  class="col card"
                  :class="{ main: billings.billsCount }"
                >
                  <div class="card-body">
                    <NoticeIcon v-if="billings.hasImbalances" class="float-right danger" width="16px" height="16px" />
                    <div>{{ $tc('activity.bills', billings.billsCount, { count: billings.billsCount }) }}</div>
                    <div class="text-muted">{{ formatMoney(billings.billsAmount) }}</div>
                  </div>
                </div>
                <div v-if="billings.creditsCount" class="col card counter">
                  <div class="card-body">
                    <div>{{ $tc('activity.credits', billings.creditsCount, { count: billings.creditsCount }) }}</div>
                    <div class="text-muted">{{ formatMoney(billings.creditsAmount) }}</div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #footer>
          <td>{{ $tc('activity.suppliers', summary.suppliers, { count: summary.suppliers }) }}</td>
          <td>
            <div>{{ $tc('activity.orders', summary.orders.count, { count: summary.orders.count }) }}</div>
            <div class="text-muted">{{ formatMoneyShekels(summary.orders.amount) }}</div>
          </td>
          <td>{{ $tc('activity.deliveries', summary.deliveries.count, { count: summary.deliveries.count }) }}</td>
          <td>
            <div class="row g-0">
              <div v-if="summary.billings.billsCount || !summary.billings.creditsCount" class="col">
                <div>
                  {{ $tc('activity.bills', summary.billings.billsCount, { count: summary.billings.billsCount }) }}
                </div>
                <div class="text-muted">{{ formatMoney(summary.billings.billsAmount) }}</div>
              </div>
              <div v-if="summary.billings.creditsCount" class="col">
                <div>
                  {{ $tc('activity.credits', summary.billings.creditsCount, { count: summary.billings.creditsCount }) }}
                </div>
                <div class="text-muted">{{ formatMoney(summary.billings.creditsAmount) }}</div>
              </div>
            </div>
          </td>
        </template>
      </Table>
    </div>
  </PageLayout>
</template>

<script>
import { ref, computed, getCurrentInstance, watch } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { useQuery } from '@vue/apollo-composable';
import { groupBy, map, keys, pipe, flatten, uniq, mergeWith, add, pick, prop, sum } from 'ramda';
import Fuse from 'fuse.js';
import { DateTime } from 'luxon';

import { NoticeIcon } from '@/assets/icons';
import { PageLayout, MonthPicker } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import Table from '@/modules/core/components/layouts/Table';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { EVENT_OVERVIEW_QUERY } from './compositions/graphql';

const groupBySupplier = groupBy(({ supplier }) => supplier?.id);

const summarizeOrders = (orders = []) => ({
  count: orders.length,
  amount: sum(orders.map(prop('amount'))),
  hasDiffs: orders.some((order) => (order.diffs || []).length),
  hasOrderedProducts: orders.some((order) => order.products.some(({ quantity }) => quantity > 0)),
  hasReturnedProducts: orders.some((order) => order.products.some(({ quantity }) => quantity < 0)),
});

const summarizeDeliveries = (deliveries = []) => ({
  count: deliveries.length,
  hasDiffs: deliveries.some((delivery) => (delivery.diffs || []).length),
  hasImbalances: deliveries.some((delivery) => (delivery.imbalances || []).length),
  hasReceivedProducts: deliveries.some((delivery) => delivery.products.some(({ quantity }) => quantity > 0)),
  hasReturnedProducts: deliveries.some((delivery) => delivery.products.some(({ quantity }) => quantity < 0)),
});

const summarizeBillings = (billings = []) => {
  const bills = billings.filter(({ totalAmount }) => totalAmount > 0);
  const credits = billings.filter(({ totalAmount }) => totalAmount < 0);
  return {
    billsCount: bills.length,
    billsAmount: sum(bills.map(prop('totalAmount'))),
    creditsCount: credits.length,
    creditsAmount: Math.abs(sum(credits.map(prop('totalAmount')))),
    hasImbalances: bills.some((bill) => (bill.imbalances || []).length),
  };
};

const getDefaultMonth = () => {
  const date = new Date();
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};

export default {
  components: { PageLayout, MonthPicker, Table, NoticeIcon },
  setup() {
    const { $route, $router, $i18n } = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const routeQuery = ref($route.query);
    const selectedMonth = ref(routeQuery.value?.month || getDefaultMonth());
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    watch(selectedMonth, (newMonth) => {
      $router.replace({ query: { month: newMonth } });
      selectedMonth.value = newMonth;
    });

    onBeforeRouteUpdate((to, _, next) => {
      routeQuery.value = to.query;
      next();
    });

    const formattedMonth = computed(() => {
      return new Date(selectedMonth.value).toLocaleDateString($i18n.locale, { month: 'long', year: 'numeric' });
    });

    const variables = computed(() => {
      const from = DateTime.fromFormat(selectedMonth.value, 'yyyy-MM').startOf('month').toISODate();
      const to = DateTime.fromFormat(selectedMonth.value, 'yyyy-MM').endOf('month').toISODate();
      return { from, to, businessId: currentTenant.value.id };
    });
    const { result, loading } = useQuery(EVENT_OVERVIEW_QUERY, variables);
    const data = computed(() => ({
      orders: result.value?.orders?.nodes ?? [],
      deliveries: result.value?.deliveries?.nodes ?? [],
      billings: result.value?.billings?.nodes ?? [],
    }));
    const overviewItems = computed(() => {
      const ordersBySupplier = groupBySupplier(data.value.orders);
      const deliveriesBySupplier = groupBySupplier(data.value.deliveries);
      const billingsBySupplier = groupBySupplier(data.value.billings);
      const supplierIds = pipe(map(keys), flatten, uniq)([ordersBySupplier, deliveriesBySupplier, billingsBySupplier]);

      return supplierIds
        .map((supplierId) => ({
          supplier: (ordersBySupplier[supplierId] ||
            deliveriesBySupplier[supplierId] ||
            billingsBySupplier[supplierId])[0].supplier,
          orders: summarizeOrders(ordersBySupplier[supplierId]),
          deliveries: summarizeDeliveries(deliveriesBySupplier[supplierId]),
          billings: summarizeBillings(billingsBySupplier[supplierId]),
        }))
        .sort(({ supplier: supplier1 }, { supplier: supplier2 }) => supplier1?.name.localeCompare(supplier2?.name));
    });

    const supplierSearch = ref();
    const filteredOverviewItems = computed(() => {
      if (!supplierSearch.value) return overviewItems.value;

      const fuseOptions = {
        threshold: 0.3,
        keys: ['supplier.name'],
        shouldSort: false,
      };
      const fuse = new Fuse(overviewItems.value, fuseOptions);
      return fuse.search(supplierSearch.value).map(({ item }) => item);
    });

    const summary = computed(() => ({
      suppliers: filteredOverviewItems.value.length,
      ...filteredOverviewItems.value.map(pick(['orders', 'deliveries', 'billings'])).reduce(mergeWith(mergeWith(add)), {
        orders: { count: 0, amount: 0 },
        deliveries: { count: 0 },
        billings: { billsCount: 0, billsAmount: 0, creditsCount: 0, creditsAmount: 0 },
      }),
    }));

    const formatMoney = (value) => {
      return formatCentsToCurrency(value) ?? '-';
    };

    const formatMoneyShekels = (value) => {
      return formatToCurrency(value) ?? '-';
    };

    const routeToSupplier = (supplierId) => {
      $router.push({ name: 'supplierActivity', params: { supplierId }, query: routeQuery.value });
    };

    return {
      loading,
      supplierSearch,
      filteredOverviewItems,
      summary,
      selectedMonth,
      formatMoney,
      formatMoneyShekels,
      routeToSupplier,
      formattedMonth,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.table-container-hight {
  height: calc(100vh - #{$page-layout-header-hight + $tenant-header-height});
}

.warning {
  color: $warning;
}
.danger {
  color: $error;
}

::v-deep table.styled-table {
  border-collapse: separate;
  border-spacing: 0.5rem 0;
}
th {
  padding: 0 !important;
  border-bottom: 0;
  width: 25%;
}
td {
  padding: 0.5rem !important;
  border-bottom: 0;

  tbody tr:hover & {
    background: #f8fafb;
  }

  tbody &:not(:first-child) {
    background: #f8fafb;
    border-left: 1px solid $outline;
    border-right: 1px solid $outline;
  }

  tbody tr:first-child &:not(:first-child) {
    background: #f8fafb;
    border-left: 1px solid $outline;
    border-right: 1px solid $outline;
    border-top: 1px solid $outline;
    border-radius: 6px 6px 0 0;
  }
}

.card {
  height: 5em;
  box-shadow: 0 2px 15px rgba(62, 95, 128, 0.07), 0 2px 2px rgba(62, 95, 128, 0.1);

  &::before,
  &::after {
    content: '';
    visibility: hidden;
    width: 4px;
    height: 23px;
    top: -10px;
    position: absolute;
    transform: rotate(90deg);
  }

  $horizontal-position: 10px;
  $left-direction-radius: 16px 0px 16px 0px;
  $right-direction-radius: 0px 16px 0px 16px;

  &.counter::before {
    visibility: visible;
    background: $counter-action;

    [dir='ltr'] & {
      right: $horizontal-position;
      border-radius: $left-direction-radius;
    }
    [dir='rtl'] & {
      left: $horizontal-position;
      border-radius: $right-direction-radius;
    }
  }
  &.main::after {
    visibility: visible;
    background: $main-action;

    [dir='ltr'] & {
      left: $horizontal-position;
      border-radius: $right-direction-radius;
    }

    [dir='rtl'] & {
      right: $horizontal-position;
      border-radius: $left-direction-radius;
    }
  }
}

.card + .card {
  margin-left: 0.5rem;
  [dir='rtl'] & {
    margin-right: 0.5rem;
    margin-left: 0;
  }
}

tr:hover .card {
  transform: translateY(-3px);
  box-shadow: 0 12px 24px rgba(95, 102, 109, 0.14), 0 8px 4px rgba(0, 0, 0, 0.06);
}

.card-body {
  padding: 0.5em;
}

::v-deep tfoot {
  tr {
    height: auto;
  }
  td {
    vertical-align: top;
  }
}
</style>
