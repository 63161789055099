export function calcBasePrice(price, discount = 0, discountType = 'percent', roundResult = true) {
  const result = discountType === 'percent' ? price * ((100 - discount) / 100) : price - discount;
  return roundResult ? Math.round(result) : result;
}

export function wrapPromise(func) {
  return function (...args) {
    return new Promise((resolve, reject) =>
      func.call(this, ...args, (error, result) => (error ? reject(error) : resolve(result)))
    );
  };
}

export function generateSharedDebounce() {
  let timer;
  function debounce(func, timeout = 30) {
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const destroy = () => clearTimeout(timer);
  return { debounce, destroy };
}
