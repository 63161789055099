import ExcelJS from 'exceljs/dist/exceljs.js';
import FileSaver from 'file-saver';

import i18n from '@/imports/startup/client/i18n';

const EXCEL_MIMETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export function exportDocuments(exportData) {
  const workbook = new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet(i18n.t('documentsExportExcel.worksheetName'));
  sheet.views = [{ rightToLeft: i18n.direction() === 'rtl' }];

  const titleCell = sheet.getCell('A1');
  titleCell.value = i18n.t('documentsExportExcel.restaurant');
  titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 0, tint: -0.0499893185216834 } };
  sheet.getCell('B1').value = exportData.restaurantName;

  sheet.columns = [
    { key: 'supplierKey' },
    { key: 'supplierName' },
    { key: 'type' },
    { key: 'issueDate' },
    { key: 'docNumber' },
    { key: 'paymentDue' },
    { key: 'taxAmount' },
    { key: 'totalAmount' },
    { key: 'exceptional' },
  ];

  sheet.addRow();
  const headerRow = sheet.addRow(sheet.columns.map((col) => i18n.t(`documentsExportExcel.${col.key}`)));
  headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 0, tint: -0.0499893185216834 } };
  exportData.documents.forEach((doc) => {
    sheet.addRow(doc);
  });

  sheet.columns.forEach((column) => {
    column.width = Math.max(12, ...column.values.filter((v) => v.length).map((v) => v.length));
  });

  return workbook.xlsx.writeBuffer().then((buffer) => {
    return FileSaver.saveAs(
      new Blob([buffer], { type: EXCEL_MIMETYPE }),
      `${i18n.t('documentsExportExcel.filename')}.xlsx`
    );
  });
}

export function exportToExcel(metadata, data) {
  const workbook = new ExcelJS.Workbook();
  const sheetsMetadata = metadata.sheets || [metadata];
  const sheetsData = metadata.sheets ? data : [data];

  sheetsMetadata.forEach((sheetMetadata, index) => {
    const sheetData = sheetsData[index];
    const sheet = workbook.addWorksheet(sheetMetadata.sheetname);
    sheet.views = [{ rightToLeft: metadata.direction === 'rtl' }];
    const alignment = {
      horizontal: metadata.direction === 'rtl' ? 'right' : 'left',
      readingOrder: metadata.direction,
    };
    sheet.columns = sheetMetadata.columns.map((col) => ({ key: col.key, style: { alignment, numFmt: col.numFmt } }));

    // add header row
    const headerRow = sheet.addRow(sheet.columns.map((col, index) => sheetMetadata.columns[index].label));
    headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 0, tint: -0.0499893185216834 } };

    // add data rows
    sheetData.forEach((entry) => {
      sheet.addRow(entry);
    });

    // fix column widths based on content length
    sheet.columns.forEach((column) => {
      column.width = Math.max(12, ...column.values.filter((v) => v.length).map((v) => v.length));
    });
  });

  return workbook.xlsx.writeBuffer().then((buffer) => {
    return FileSaver.saveAs(new Blob([buffer], { type: EXCEL_MIMETYPE }), `${metadata.filename}.xlsx`);
  });
}
