import Vue from 'vue';
import Vuex from 'vuex';

import { Suppliers, Items, Terms, PriceIndexes } from '@/imports/api/collections';
import apiConnection from '@/imports/api/api_connection';

Vue.use(Vuex);

const toCamelCase = (s) => s.replace(/([-_][a-z])/gi, (match) => match.toUpperCase().replace('-', '').replace('_', ''));

function createCollectionsSyncPlugin(collections) {
  return (store) => {
    const state = {};
    collections.forEach((collection) => {
      const collectionName = toCamelCase(collection._name);
      state[collectionName] = [];
      collection.find({}).observe({
        added(doc) {
          store.commit('collections/added', { name: collectionName, doc });
        },
        changed(doc) {
          store.commit('collections/changed', { name: collectionName, doc });
        },
        removed(doc) {
          store.commit('collections/removed', { name: collectionName, doc });
        },
      });
    });
    store.registerModule('collections', {
      namespaced: true,
      state,
      mutations: {
        added(state, { name, doc }) {
          state[name].push(doc);
        },
        changed(state, { name, doc }) {
          const index = state[name].findIndex((d) => d._id === doc._id);
          state[name].splice(index, 1, doc);
        },
        removed(state, { name, doc }) {
          const index = state[name].findIndex((d) => d._id === doc._id);
          state[name].splice(index, 1);
        },
      },
    });
  };
}

const store = new Vuex.Store({
  state: {
    itemTermsLoading: false,
    itemTermsData: {
      purchases: [],
      priceCreeps: [],
      issues: [],
      lastBill: {},
    },
  },
  mutations: {
    setItemTermsLoading(state, value) {
      state.itemTermsLoading = value;
    },
    setItemTermsData(state, data) {
      state.itemTermsData = data;
    },
  },
  actions: {
    async fetchItemTermsData() {},
    releaseItemTermsData() {},
    async updateTerm(context, { termId, term }) {
      await apiConnection.callPromise('suppliers.updateTermNew', { termId, term });
    },
    async createTerm(context, term) {
      await apiConnection.callPromise('suppliers.createTerm', term);
    },
    async fetchItemDeliveryAndBilling() {},
  },
  plugins: [createCollectionsSyncPlugin([Suppliers, Items, Terms, PriceIndexes])],
});

export default store;
