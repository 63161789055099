const options = {
  short: {},
  twoDigits: {
    day: '2-digit',
    year: '2-digit',
    month: '2-digit',
  },
  long: { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' },
  full: {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  dateAndTime: {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
};

export { options };
