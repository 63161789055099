import { computed } from 'vue';

import { useTenancy } from '@/modules/auth';

const currencyCodes = {
  ILS: 'ILS',
  USD: 'USD',
};

export const useCurrency = () => {
  const { currentTenant } = useTenancy();
  const currencyFormat = computed(() => {
    const countryCode = currentTenant.value?.countryCode;
    const currency = countryCode === 'IL' ? currencyCodes.ILS : currencyCodes.USD;
    return {
      style: 'currency',
      currency: currency,
    };
  });
  return {
    currencyFormat,
  };
};
