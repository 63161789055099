const percent = {
  style: 'percent',
  maximumFractionDigits: 2,
};

const percentSignDisplay = {
  style: 'percent',
  maximumFractionDigits: 2,
  signDisplay: 'exceptZero',
};

const float2digits = {
  maximumFractionDigits: 2,
};

const currency = {
  style: 'currency',
  currency: 'ILS',
};

const currencyInteger = {
  style: 'currency',
  currency: 'ILS',
  maximumFractionDigits: 0,
};

const currencyFloat = {
  style: 'currency',
  currency: 'ILS',
  maximumFractionDigits: 2,
};

export { currency, currencyInteger, currencyFloat, percent, percentSignDisplay, float2digits };
