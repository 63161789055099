<template>
  <el-container v-if="dataReady">
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="page-breadcrumbs">
        <slot name="breadcrumb"></slot>
      </el-breadcrumb>
      <slot name="headerSide"></slot>
    </el-header>

    <el-container style="flex-direction: row-reverse">
      <el-aside v-if="$slots.sideBar" width="200px" class="px-2" style="padding-top: 20px">
        <slot name="sideBar"></slot>
      </el-aside>

      <el-main class="main-content">
        <slot></slot>
      </el-main>
    </el-container>
  </el-container>
  <el-container v-else v-loading="true" class="loading-container">
    <el-header></el-header>
  </el-container>
</template>

<script type="text/javascript">
export default {
  meteor: {},
  props: {
    ready: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    dataReady() {
      const subsReady = Object.keys(this.$subReady).reduce((ready, key) => this.$subReady[key] && ready, true);
      return subsReady && this.ready;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/deprecated/dimensions';

.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-breadcrumbs {
  display: flex;
  font-size: 120%;
}

.main-content {
  // 60px is the height of the breadcrumbs
  height: calc(100vh - #{60px + $header-height});
  overflow-y: auto;
}
</style>
