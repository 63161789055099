<template>
  <div v-loading="loading" class="h-100 w-100" />
</template>

<script type="text/javascript">
import { getCurrentInstance } from 'vue';
import { Auth } from 'aws-amplify';

import { useTenants } from '@/modules/core/compositions/tenants';

export default {
  async beforeRouteEnter(to, from, next) {
    const session = await Auth.currentSession();
    const memberships = JSON.parse(session.getIdToken().payload?.memberships ?? '') ?? [];

    if (memberships.length === 1 && memberships[0].role !== 'admin') {
      return next({ name: 'overview', params: { tenantId: memberships[0].businessId } });
    }
    next();
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { tenants, loading, onResult } = useTenants();

    onResult((returnedTenants) => {
      if (returnedTenants.data) {
        const tenants = returnedTenants.data.tenantSelection.nodes;
        root.$router.push({
          name: 'overview',
          params: { tenantId: tenants[0].id },
        });
      }
    });

    return { tenants, loading };
  },
};
</script>
